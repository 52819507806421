import React from "react";
import styled from "styled-components";
import Opta6 from "./OptaTypes.js/Opta6";
import Opta16 from "./OptaTypes.js/Opta16";
import Opta17 from "./OptaTypes.js/Opta17";
import Opta18 from "./OptaTypes.js/Opta18";
import Opta19 from "./OptaTypes.js/Opta19";
import Opta30 from "./OptaTypes.js/Opta30";
import Opta32 from "./OptaTypes.js/Opta32";
import Opta70 from "./OptaTypes.js/Opta70";
import Opta4 from "./OptaTypes.js/Opta4";
import Opta1 from "./OptaTypes.js/Opta1";
import Opta13 from "./OptaTypes.js/Opta13";
import Opta14 from "./OptaTypes.js/Opta14";
import Opta15 from "./OptaTypes.js/Opta15";
import Opta24 from "./OptaTypes.js/Opta24";
import Opta79 from "./OptaTypes.js/Opta79";
import LiveScore from "./OptaTypes.js/LiveScore";

const Main = styled.div`
  border-radius: 4px;
  background-color: #202731;
  height: 78%;
  min-width: 188px;
  margin: 0.2em;
  cursor: pointer;
  box-sizing: border-box;
  padding: 6px;
  display: flex;
  opacity: ${(props) => (props.faded ? "0.4" : "1")};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  :hover {
    background-color: #0adb93;
    color: #000;
  }
`;
const TeamColour = styled.div`
  height: 100%;
  width: 3px;
  border-radius: 4px;
  background-color: rgb(0, 255, 252);
`;
const Content = styled.div`
  padding: 0.2em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const Footer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: auto;
`;
export default function OptaEvent({
  matchEvent,
  previewGraphic,
  game,
  index,
  events,
  teams,
}) {
  let player = game?.lineup?.home?.find(
    (p) => p.squad?.player?.opta_ID === matchEvent.playerId
  );
  if (!player) {
    player = game?.lineup?.away?.find(
      (p) => p.squad?.player?.opta_ID === matchEvent.playerId
    );
  }
  let team =
    game?.home_team?.opta_ID === matchEvent.contestantId
      ? game?.home_team
      : game?.away_team;

  let kit =
    game?.home_team?.opta_ID === matchEvent.contestantId
      ? game.home_team?.variables?.[game?.home_team_kit + "_kit_bg"] ||
        "transparent"
      : game.away_team?.variables?.[game?.away_team_kit + "_kit_bg"] ||
        "transparent";

  matchEvent = {
    ...matchEvent,
    game_time:
      matchEvent?.timeMin?.toString()?.padStart(2, "0") +
      ":" +
      matchEvent?.timeSec?.toString()?.padEnd(2, "0"),
  };

  return (
    <Main faded={index !== 0}>
      <TeamColour
        style={{
          backgroundColor: kit || "transparent",
        }}
      />

      <Content>
        {/* {matchEvent.id} */}
        {matchEvent.typeId === 1 && (
          <Opta1
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 4 && (
          <Opta4
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 6 && (
          <Opta6
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 13 && (
          <Opta13
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 14 && (
          <Opta14
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 15 && (
          <Opta15
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 16 && (
          <Opta16
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 17 && (
          <Opta17
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 18 && (
          <Opta18
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
            playerOn={events.find((i) => {
              return (
                i.contestantId === matchEvent?.contestantId &&
                i.eventId ===
                parseInt(
                  matchEvent.qualifier?.find((q) => q.qualifierId === 55)?.value
                )
              );
            })}
          />
        )}
        {matchEvent.typeId === 19 && (
          <Opta19
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
            playerOff={events.find((i) => {
              return (
                i.contestantId === matchEvent?.contestantId &&
                i.eventId ===
                parseInt(
                  matchEvent.qualifier?.find((q) => q.qualifierId === 55)?.value
                )
              );
            })}
          />
        )}
        {matchEvent.typeId === 24 &&
          matchEvent?.qualifier?.findIndex((q) => q?.qualifierId === 49) >
            -1 && (
            <Opta24
              item={matchEvent}
              previewGraphic={previewGraphic}
              player={player}
              team={team}
              game={game}
            />
          )}
        {matchEvent.typeId === 30 && (
          <Opta30
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 32 && (
          <Opta32
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 70 && (
          <Opta70
            item={matchEvent}
            previewGraphic={previewGraphic}
            player={player}
            team={team}
            game={game}
          />
        )}
        {matchEvent.typeId === 79 && (
          <Opta79
            item={matchEvent}
            previewGraphic={previewGraphic}
            game={game}
          />
        )}
        {matchEvent.typeId === "live_score" && (
          <LiveScore
            item={matchEvent}
            events={events}
            previewGraphic={previewGraphic}
          />
        )}
        <Footer>
          <div>
            {matchEvent.timeMin?.toString().padStart(2, "0") +
              ":" +
              matchEvent.timeSec?.toString().padStart(2, "0")}
          </div>
        </Footer>
      </Content>
    </Main>
  );
}
