import React, { useCallback, useContext, useEffect, useState } from "react";
import RightSideBar from "../RightSideBar/RightSideBar";
import styled from "styled-components";
import { GraphicsContext } from "../../contexts/GraphicsContext";
import TeamNames from "../TopBar/TeamNames";
import Goal from "../TopBar/Goal";
import { UIContext } from "../../contexts/UIContext";
import ClockEdit from "../ClockEdit/ClockEdit";
import { Route, Switch, useParams } from "react-router";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import Goals from "../Goals/Goals";
import Penalties from "../Penalties/Penalties";
import { APIContext } from "../../contexts/APIContext";
import EFLGame from "../Game/EFLGame";
import EFLTeamNames from "../TopBar/EFLTeamNames";
import EFLKit from "../Kit/EFLKit";
import moment from "moment";
import { MonitorContext } from "../../contexts/MonitorContext";
import { GameEventsContext } from "../../contexts/GameEventContext";
import PSRightSideBar from "../RightSideBar/PSRightSideBar";
import PSGame from "../Game/PSGame";

const OPTA_PERIODS = [
  { value: 16, name: "Pre-match" },
  { value: 1, name: "First half" },
  { value: 10, name: "Half time" },
  { value: 2, name: "Second half" },
  { value: 3, name: "Extra time first half" },
  { value: 4, name: "Extra time second half" },
  { value: 5, name: "Penalty shootout" },
  { value: 11, name: "End of second half (before extra time)" },
  { value: 12, name: "Extra time half time" },
  { value: 13, name: "End of extra time (before penalties)" },
  { value: 14, name: "Full time" },
];

const LINEUP_FIELDS = gql`
  fragment LINEUP_FIELDS on LineupObject {
    home {
      squad {
        _id
        shirt_number
        opta_shirt_number
        player {
          _id
          opta_ID
          first_name
          last_name
          first_name_upper
          last_name_upper
          opta_first_name
          opta_last_name
          nationality {
            _id
            opta_code
            opta_name
          }
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
    away {
      squad {
        _id

        shirt_number
        opta_shirt_number
        player {
          _id
          opta_ID
          first_name
          last_name
          first_name_upper
          last_name_upper
          opta_first_name
          opta_last_name
          nationality {
            _id
            opta_code
            opta_name
          }
        }
      }
      order
      yellow_card
      dbl_yellow_card
      red_card
      sub_order
      subbed
      captain
    }
  }
`;
const GAME_BY_ID = gql`
  ${LINEUP_FIELDS}
  query GameByID($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      date
      week
      opta_status
      opta_scores
      home_team_kit
      away_team_kit
      opta_team_stats
      opta_player_stats
      stadium {
        opta_name
        name
      }
      competition {
        _id
        name
        badge
        competition_type
        colour
      }
      officials {
        official {
          first_name
          last_name
        }
        type
      }
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      lineup {
        ...LINEUP_FIELDS
      }
      home_team {
        _id
        opta_ID
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        opta_ID
        name
        short_name
        code
        variables
      }
      live_data
      home_formation
      away_formation
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
      config
      agg_home_score
      agg_away_score
      leg
    }
  }
`;

const GAME_SUBSCRIPTION = gql`
  ${LINEUP_FIELDS}
  subscription GameUpdated($id: String!) {
    gameUpdated(_id: $id) {
      _id
      time
      home_team_kit
      away_team_kit
      lineup {
        ...LINEUP_FIELDS
      }
      live_data
      opta_team_stats
      opta_player_stats
      home_scorers
      away_scorers
      home_penalties
      away_penalties
      home_formation
      away_formation
      agg_home_score
      agg_away_score
      leg
      season {
        _id
      }
      commentators {
        first_name
        last_name
      }
      home_team {
        _id
        opta_ID
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        opta_ID
        name
        short_name
        code
        variables
      }
    }
  }
`;
const OPTA_EVENT_SUBSCRIPTION = gql`
  subscription newOptaEvents($id: String!) {
    newOptaEvents(id: $id) {
      _id
      id
      game_ID {
        _id
      }
      seqId
      typeId
      timeMin
      timeSec
      qualifier
      playerId
      contestantId
      eventId
      periodId
      timeStamp
      outcome
      x
      y
    }
  }
`;
const OPTA_EVENT_BY_GAME = gql`
  query OptaEventByGame($id: String) {
    OptaEventByGame(id: $id) {
      _id
      id
      game_ID {
        _id
      }
      seqId
      typeId
      timeMin
      timeSec
      qualifier
      playerId
      contestantId
      eventId
      periodId
      timeStamp
      outcome
      x
      y
    }
  }
`;
const LIVE_SCORES = gql`
  query LiveScores($date: String!) {
    liveScores(date: $date) {
      _id
      playerName
      timeMin
      timeSec
      timeStamp
      contestantId
      qualifier
      periodId
      game_ID {
        _id
        competition {
          _id
          name
          colour
        }
        home_team {
          opta_ID
          code
          variables
        }
        away_team {
          opta_ID
          code
          variables
        }
      }
    }
  }
`;
const Main = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
`;
const EngineContent = styled.div`
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;
const Row = styled.div`
  display: flex;
`;
const RightBar = styled.div`
  display: flex;
  width: 300px;
  box-shadow: -3px 1px 3px 0 rgb(0 0 0 / 42%);
`;
const LeftBar = styled.div`
  display: flex;
  width: 44px;
  box-shadow: 3px -1px 3px 0 rgb(0 0 0 / 42%);
  background-color: #202731;
`;
const CurrentGraphic = styled.div`
  width: 80%;
  height: 265px;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  padding-top: 0.5em;

  bottom: 12px;
  margin: 0px;
`;
const BGToggle = styled.div`
  width: 80%;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  padding-top: 0.5em;

  bottom: 12px;
  margin: 0px;
`;
const GraphicButton = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.green ? "#0adb93" : props.red ? "#db0a41" : "#181d25"};
  color: ${(props) => (props.green ? "#1d1d1d" : "")};
  min-height: 100px;
  width: 30%;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${(props) =>
      props.green ? "#0adb93" : props.red ? "#db0a41" : "#db0a41"};
  }
`;
export default function PS({
  setSelectedPlayer,
  selectedPlayer,
  setSelectedSubPlayer,
  selectedSubPlayer,
}) {
  let { gameID } = useParams();
  const [game, setGame] = useState();
  const [group, setGroup] = useState();
  const [groups, setGroups] = useState();
  const [homeManager, setHomeManager] = useState();
  const [awayManager, setAwayManager] = useState();
  const [optaEvents, setOptaEvents] = useState();
  const [allOptaEvents, setAllOptaEvents] = useState();
  const [liveScores, setLiveScores] = useState();
  const { setGame: setGameMonitor, setPage } = useContext(MonitorContext);
  const { setEvents, setGame: setEventsGame } = useContext(GameEventsContext);
  const [getGame, { data, subscribeToMore }] = useLazyQuery(GAME_BY_ID, {
    fetchPolicy: "network-first",
  });
  const [getLiveScores, { data: liveScoresData }] = useLazyQuery(LIVE_SCORES, {
    fetchPolicy: "network-first",
    pollInterval: 1000 * 10,
  });
  const [
    getOptaEvent,
    { data: optaEventData, subscribeToMore: subscribeToMoreOPTA, client },
  ] = useLazyQuery(OPTA_EVENT_BY_GAME, {
    fetchPolicy: "network-first",
  });

  useEffect(() => {
    if (gameID) {
      getGame({ variables: { id: gameID } });
      getOptaEvent({ variables: { id: gameID } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameID]);

  useEffect(() => {
    let unsubscribe;
    if (subscribeToMore) {
      console.log("Subscribed");
      unsubscribe = subscribeToMore({
        document: GAME_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const updatedGame = subscriptionData.data.gameUpdated;
          let newGame = {
            gameById: { ...prev.gameById, ...updatedGame },
          };

          return newGame;
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMore, gameID]);

  useEffect(() => {
    let unsubscribe;
    if (subscribeToMoreOPTA) {
      console.log("Subscribed");
      unsubscribe = subscribeToMoreOPTA({
        document: OPTA_EVENT_SUBSCRIPTION,
        variables: { id: gameID },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          let new_obj = [...(prev?.OptaEventByGame || [])];
          // if (
          //   new_obj?.length > 0 &&
          //   new_obj?.filter(
          //     (ev) =>
          //       ev?.seqId > subscriptionData.data.newOptaEvents[0]?.seqId - 5
          //   )?.length === 0
          // ) {
          //   client.resetStore();
          //   return { OptaEventByGame: new_obj };
          // }
          for (
            let i = 0;
            i < subscriptionData.data.newOptaEvents?.length;
            i++
          ) {
            let ev = subscriptionData.data.newOptaEvents[i];
            new_obj = [
              ...(new_obj?.filter((e) => {
                return e.id !== ev.id;
              }) || []),
              ev,
            ];
          }

          return { OptaEventByGame: new_obj };
        },
      });
    }
    return () => {
      console.log("Unsubscribed");
      unsubscribe && unsubscribe();
    };
  }, [subscribeToMoreOPTA, gameID, client]);

  useEffect(() => {
    if (data) {
      setEventsGame(data.gameById);
      setGame(data.gameById);
      setGameMonitor(data.gameById);
      setPage("Main");
      getLiveScores({
        variables: { date: data.gameById?.date?.replace("Z", "") },
      });
    }
  }, [data]);

  useEffect(() => {
    if (liveScoresData) {
      console.log(liveScoresData.liveScore);
      setLiveScores(liveScoresData.liveScores);
    }
  }, [liveScoresData]);

  useEffect(() => {
    if (optaEventData) {
      setEvents(
        [...(optaEventData.OptaEventByGame || [])].sort((a, b) => {
          let period_a = OPTA_PERIODS?.findIndex((p) => p.value === a.periodId);
          let period_b = OPTA_PERIODS?.findIndex((p) => p.value === b.periodId);

          if (period_a < period_b) {
            return -1;
          }
          if (period_a > period_b) {
            return 1;
          }
          if (a?.timeMin < b?.timeMin) {
            return -1;
          }
          if (a?.timeMin > b?.timeMin) {
            return 1;
          }

          if (a?.timeSec < b?.timeSec) {
            return -1;
          }
          if (a?.timeSec > b?.timeSec) {
            return 1;
          }
          return a?.timestamp - b?.timestamp;
        })
      );

      let sorted = [...optaEventData.OptaEventByGame];
      sorted = sorted.concat(liveScores ?? [])?.map((score) => {
        return { typeId: "live_score", ...score };
      });
      setAllOptaEvents(sorted);
      sorted = sorted
        .filter((item) => {
          // if (
          //   item.typeId === 1 || //PASS
          //   item.typeId === 2 || //Offside Pass
          //   item.typeId === 3 || //Take On
          //   item.typeId === 5 || //Out
          //   item.typeId === 7 || //Tackle
          //   item.typeId === 8 || //Interception
          //   item.typeId === 10 || //Save
          //   item.typeId === 11 || //Claim
          //   item.typeId === 12 || //Clearance
          //   item.typeId === 27 || //Start delay
          //   item.typeId === 28 || //End delay
          //   item.typeId === 34 || //TEAM SETUP
          //   item.typeId === 37 || //	Collection End
          //   item.typeId === 40 || //Formation change
          //   item.typeId === 41 || //Punch
          //   item.typeId === 42 || //Good skill
          //   item.typeId === 43 || //Deleted event
          //   item.typeId === 44 || //Aerial
          //   item.typeId === 45 || //Challenge
          //   item.typeId === 49 || //Ball recovery
          //   item.typeId === 50 || //Dispossessed
          //   item.typeId === 51 || //Error
          //   item.typeId === 52 || //Keeper pick-up
          //   item.typeId === 55 || //Offside provoked
          //   item.typeId === 59 || //Keeper Sweeper
          //   item.typeId === 61 || //Ball touch
          //   item.typeId === 74 //Blocked Pass
          // ) {
          //   return false;
          // }
          // if (
          //   item.typeId === 4 || //FOUL
          //   item.typeId === 6 || //Corners
          //   item.typeId === 13 || //Shot Miss
          //   item.typeId === 14 || //Shot Hit Post
          //   item.typeId === 15 || //Shot Saved
          //   item.typeId === 16 || //Goal
          //   item.typeId === 17 || //Card
          //   item.typeId === 18 || //Card
          //   item.typeId === 19 || //Card
          //   item.typeId === 30 || //End Period
          //   item.typeId === 32 || //KICK OFF
          //   item.typeId === 70 //Injury Time Announcement
          // ) {
          //   return true;
          // }
          // dont show corners in timeline
          // else if (item.typeId === 6) {
          //   if (item.outcome === 1) {
          //     return true;
          //   }
          // }
          //FOR EFL
          if (
            item.typeId === 16 ||
            item.typeId === 17 ||
            item.typeId === 18 ||
            item.typeId === 19 ||
            item.typeId === 24 ||
            item.typeId === 30 ||
            item.typeId === 32 ||
            item.typeId === 70 ||
            item.typeId === 79 ||
            item.typeId === 922 ||
            item.typeId === "live_score"
          ) {
            return true;
          }

          return false;
        })
        .sort((a, b) => {
          if (a?.typeId !== "live_score" && b?.typeId !== "live_score") {
            let period_a = OPTA_PERIODS?.findIndex(
              (p) => p.value === a.periodId
            );
            let period_b = OPTA_PERIODS?.findIndex(
              (p) => p.value === b.periodId
            );

            if (period_a < period_b) {
              return -1;
            }
            if (period_a > period_b) {
              return 1;
            }
            if (a?.timeMin < b?.timeMin) {
              return -1;
            }
            if (a?.timeMin > b?.timeMin) {
              return 1;
            }

            if (a?.timeSec < b?.timeSec) {
              return -1;
            }
            if (a?.timeSec > b?.timeSec) {
              return 1;
            }
          }

          return moment(a?.timeStamp) - moment(b?.timeStamp);
        });
      setOptaEvents(sorted);
    }
  }, [optaEventData, liveScores]);

  const { goal, setGoal } = useContext(UIContext);
  const {
    previewGraphic,
    animateGraphic,
    graphicInPreview,
    animateOffGraphic,
    updateGraphic,
    clearPVW,
    clearPreview,
    showBackground,
    setShowBackground,
  } = useContext(GraphicsContext);
  const { updateGame } = useContext(APIContext);

  if (!game) return <div>Loading</div>;
  return (
    game && (
      <Main>
        <Content>
          {!goal && (
            <EFLTeamNames
              setSelectedPlayer={setSelectedPlayer}
              homeTeam={game.home_team}
              awayTeam={game.away_team}
              game={game}
              group={group}
            />
          )}
          {goal && (
            <Goal
              goal={goal}
              setGoal={setGoal}
              cancel={() => {
                updateGame({
                  id: game._id,
                  home_scorers: game.home_scorers.filter((g) => g.player),
                  away_scorers: game.away_scorers.filter((g) => g.player),
                });
                setGoal();
              }}
            />
          )}

          <Switch>
            <Route path="/:gameID/penalties">
              <Penalties game={game} />
            </Route>
            <Route path="/:gameID/clock">
              <ClockEdit game={game} />
            </Route>
            <Route path="/:gameID/goals">
              <Goals
                game={game}
                homeLineup={game?.lineup?.home}
                awayLineup={game?.lineup?.away}
                setGoal={setGoal}
              />
            </Route>
            <Route path="/:gameID/kit">
              <EFLKit game={game} />
            </Route>

            <Route path="/:gameID">
              <PSGame
                setHomeManager={setHomeManager}
                setAwayManager={setAwayManager}
                setGoal={setGoal}
                goal={goal}
                game={game}
                setSelectedPlayer={setSelectedPlayer}
                selectedPlayer={selectedPlayer}
                setSelectedSubPlayer={setSelectedSubPlayer}
                selectedSubPlayer={selectedSubPlayer}
                optaEvents={optaEvents}
                liveScores={liveScores}
                allOptaEvents={allOptaEvents}
              />
            </Route>
          </Switch>
        </Content>

        <RightBar>
          <PSRightSideBar
            group={group}
            groups={groups}
            game={game}
            optaEvents={optaEvents}
            allOptaEvents={allOptaEvents}
            homeManager={homeManager}
            awayManager={awayManager}
            previewGraphic={previewGraphic}
            graphicInPreview={graphicInPreview}
            animateGraphic={animateGraphic}
            animateOffGraphic={animateOffGraphic}
            updateGraphic={updateGraphic}
            selectedPlayer={selectedPlayer}
            selectedSubPlayer={selectedSubPlayer}
            clearPreview={clearPVW}
          />
        </RightBar>
      </Main>
    )
  );
}
